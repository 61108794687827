<template>
  <a-modal v-model:visible="isVisible" :title="modalTitle" width="700px"
           :closable="false" :mask-closable="false"
           :ok-text="okText"
           @ok="onOkClick" @cancel="onCancel">
    <a-spin :spinning="formLoading">
      <a-form ref="signatoryRef" layout="vertical" :model="form">
        <template v-if="form.signatories.length">
          <template v-for="(data, index) in form.signatories" :key="index">
            <a-divider v-if="index" style="margin-top: 10px"/>
            <a-form-item :name="['signatories', index, 'isCustom']">
              <a-checkbox v-model:checked="data.isCustom" @change="handleCustomChange(index)">
                Custom
              </a-checkbox>
            </a-form-item>

            <a-form-item :name="['signatories', index, 'officeId']"
                         :rules="!data.isCustom ? rules.officeId : rules.officeIdInput">
              <a-tree-select
                v-model:value="data.officeId"
                v-if="!data.isCustom"
                style="width: 100%" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="officeList" placeholder="Select office"
                tree-node-filter-prop="title"
                show-search allow-clear label-in-value
                @change="(value, label, extra) => { getPersonnelList(value, label, extra, index) }"
              />
              <a-input v-else
                       v-model:value="data.officeId"
                       style="width: 100%"
                       placeholder="Office Name" />
            </a-form-item>
            <a-form-item :name="['signatories', index, 'personnelId']"
                         :rules="!data.isCustom ? rules.personnelId : rules.personnelIdInput">
              <a-tree-select
                v-if="!data.isCustom"
                v-model:value="data.personnelId"
                style="width: 100%" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="data.memberList" placeholder="Select Personnel"
                tree-node-filter-prop="title"
                show-search allow-clear label-in-value
                @change="(value, label, extra) => { getPersonnelPosition(value, label, extra, index) }"
              />
              <a-input v-else
                       v-model:value="data.personnelId"
                       style="width: 100%"
                       placeholder="Personnel Name"/>
            </a-form-item>
            <a-form-item :name="['signatories', index, 'position']"
                         :rules="!data.isCustom ? rules.position : rules.positionInput">
              <a-select v-if="!data.isCustom"
                        ref="positionField"
                        v-model:value="data.position"
                        :options="positionList" placeholder="Select Personnel's Position"
                        style="width: 100%" show-search allow-clear />
              <a-input v-else
                       v-model:value="data.position"
                       style="width: 100%"
                       placeholder="Personnel's Position"/>
            </a-form-item>
            <template v-if="allowMultiple">
              <div class="pull-right signatory-icon mt-3" style="margin-top: -10px; padding-bottom: 10px">
              <span v-if="(index+1) === form.signatories.length">
                <a-button type="primary" shape="round" size="small" @click="addRow" class="mr-2">
                  <template #icon><plus-outlined /></template>
                  Add
                </a-button>
              </span>
                <span v-if="form.signatories.length > 1 && data.id === 'new'">
                <a-button type="dashed" shape="round" size="small" @click="deleteRow">
                  <template #icon><minus-outlined /></template>
                  Remove
                </a-button>
              </span>
              </div>
            </template>
          </template>
        </template>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import { defineComponent, ref, watch, toRaw, computed, createVNode } from "vue"
import { useStore } from 'vuex'
import { getPersonnelByOffice } from '@/services/api/hris'
import { Modal } from "ant-design-vue"
import { ExclamationCircleOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons-vue"

export default defineComponent({
  name: "SignatoryFormModal",
  components: {
    PlusOutlined,
    MinusOutlined,
  },
  props: {
    visible: Boolean,
    modalTitle: { type: String, default: '' },
    okText: { type: String, default: '' },
    details: { type: Object, default: () => { return {} } },
    actionType: { type: String, default: '' },
    officeList: { type: Array, default: () => { return [] } },
    memberList: { type: Array, default: () => { return [] } },
    positionList: { type: Array, default: () => { return [] } },
    formState: { type: Object, default: () => { return {} } },
  },
  emits: ['close-modal', 'submit-form', 'add-signatory', 'delete-signatory'],
  setup(props, { emit }) {
    const store = useStore()

    // DATA
    let isVisible = ref()
    let formLoading = ref(false)
    const form = ref()

    const positionField = ref()

    const listMembers = ref([])
    const labelCol = { style: { width: '150px' } }
    const wrapperCol = { span: 16 }

    const signatoryRef = ref();
    const rules = {
      officeId: {
        required: true,
        message: 'This field is required',
      },
      personnelId: {
        required: true,
        message: 'This field is required',
      },
      position: {
        required: true,
        message: 'This field is required',
      },

      officeIdInput: {
        required: true,
        message: 'This field is required',
        trigger: 'blur',
      },
      personnelIdInput: {
        required: true,
        message: 'This field is required',
        trigger: 'blur',
      },
      positionInput: {
        required: true,
        message: 'This field is required',
        trigger: 'blur',
      },
    }

    // COMPUTED
    const allowMultiple = computed(() => {
      return props.details.formId === 'cpcr' && props.details.typeId === 1
    })

    // EVENTS
    watch(() => props.visible , visible => {
      isVisible.value = visible
    })

    watch(props.memberList, list => {
      listMembers.value = list
    })

    watch(() =>  props.formState, formState => {
      form.value = formState
    }, { deep: true })

    // METHODS
    const handleCustomChange = index => {
      form.value.signatories[index].officeId = undefined
      form.value.signatories[index].isSubunit = 0
      form.value.signatories[index].personnelId = undefined
      form.value.signatories[index].position = undefined
    }

    const getPersonnelList = (officeId, label, extra, index) => {
      form.value.signatories[index].personnelId = undefined
      form.value.signatories[index].position = undefined

      if (officeId && !form.value.isCustom) {
        formLoading.value = true

        const id = officeId.value
        const isSubunit = extra.triggerNode.props.is_subunit ?? 0

        form.value.signatories[index].isSubunit = isSubunit

        getPersonnelByOffice(id, 1, isSubunit).then(response => {
          if (response) {
            const { personnel } = response
            form.value.signatories[index].memberList = personnel
          }
          formLoading.value = false
        })
      }
    }

    const getPersonnelPosition = (value, label, extra, index) => {
      form.value.signatories[index].position = undefined
      if (typeof extra.triggerNode !== 'undefined') {
        const { position } = extra.triggerNode.props
        const office = form.value.signatories[index].officeId
        const isSubunit = form.value.signatories[index].isSubunit

        let officePosition = null

        for(let key in position) {
          let obj = position[key]

          if(obj.length) {
            let officeValue = office.value

            if(isSubunit) {
              officeValue = office.value.replace("SUB", '')
            }

            officePosition = obj.find(i => {
              return i.DepartmentID === office.value || (isSubunit && i.SubunitID === parseInt(officeValue))
            })

          }
        }

        if (officePosition && typeof officePosition !== 'undefined') {
          form.value.signatories[index].position = officePosition.PositionName
          signatoryRef.value.validate()
        }
      }
    }

    const addRow = () => {
      emit('add-signatory')
    }

    const deleteRow = index => {
      emit('delete-signatory', index)
    }

    const onOkClick = () => {
      signatoryRef.value.validate().then(() => {
        Modal.confirm({
          title: () => 'Are you sure you want to save this?',
          icon: () => createVNode(ExclamationCircleOutlined),
          content: () => '',
          okText: 'Yes',
          cancelText: 'No',
          onOk() {
            const propsDetails = props.details
            const data = ref({
              typeId: propsDetails.typeId,
              formId: propsDetails.formId,
              year: propsDetails.year,
              signatories: toRaw(form.value.signatories),
              officeId: 0,
            })
            switch (propsDetails.formId){
              case 'vpopcr':
              case 'opcr':
                data.value.officeId = propsDetails.office
                break
              default:
                break
            }
            if (props.actionType === 'create') {
              store.dispatch('formManager/SAVE_POSITION_SIGNATORIES', { payload: data.value })
            } else {
              store.dispatch('formManager/UPDATE_POSITION_SIGNATORIES', { payload: data.value })
            }
            emit('close-modal')
          },
          onCancel() {},
        });
      })
      .catch(error => {
        console.log('error', error);
      });
    }

    const onCancel = () => {
      emit('close-modal')
    }

    return {
      isVisible,
      form,
      positionField,
      formLoading,
      listMembers,
      labelCol,
      wrapperCol,

      signatoryRef,
      rules,

      allowMultiple,

      handleCustomChange,
      getPersonnelList,
      getPersonnelPosition,
      addRow,
      deleteRow,
      onOkClick,
      onCancel,
    }
  },
})
</script>
<style scoped>
.signatory-icon {
  /*font-size: 20px;*/
}
</style>
